'use strict';

angular.module('appApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('ingredients-list', {
        url: '/ingredients-list?pageSize&pageNumber&filtered',
        templateUrl: 'app/ingredients-list/ingredients-list.html',
        controller: 'IngredientsListCtrl',
        authenticate: 'user'
      });
  });
